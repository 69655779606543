import {Comment, IBDDef} from "../components/BD";
import {UserCredentials} from "./wpAuth";

const baseURL:string = 'https://birdsdessines.fr';
const ratePostEndpoint:string = '/wp-json/birds/v1/mobile/ratePost';
const editorEndpoint:string = '/wp-json/birds/v1/editor';
const wpPostsEndpoint:string = "/wp-json/wp/v2/posts";
const wpUsersEndpoint:string = "/wp-json/wp/v2/users";
const wpCommentsEndpoint:string = "/wp-json/wp/v2/comments";

export interface Post {
    ID: number,
    post_date: string,
    post_date_gmt: string,
    post_content: string,
    post_title: string,
    comment_count: number,
    post_author: string,
    post_author_ID: string,
    post_author_avatar_url: string,
    post_permalink: string,
    bd_url: string,
    post_ratings: number,
    comments: Array<Comment>
}

export interface RESTPost {
    date: string | null,
    date_gmt : string | null,
    readonly guid : object,
    readonly id : number,
    readonly link : string,
    readonly modified : string,
    readonly modified_gmt : string,
    slug : string,
    readonly type : string,
    password : string,
    readonly permalink_template : string,
    title : { rendered : string, raw : string},
    content : { rendered : string, raw : string},
    author : number,
    author_name : string,
    author_avatar : string,
    excerpt : { rendered : string, raw : string},
    featured_media : number,
    comment_status : string,
    ping_status : string,
    format : string,
    meta : {
        'large-image' : string,
        'ratings_score' : number,
        'bubbles-text' : string,
        'certified' : boolean,
        'hidden' : boolean
    },
    sticky : boolean,
    template : string,
    categories : string[],
    tags : string[]
}

export interface User {
    id: number,
    name:string,
    url:string,
    description:string,
    link:string,
    slug:string,
    avatar_urls: { 24:string, 48:string, 96:string },
    meta: object
}

export interface ApplicationPassword {
    uuid: string,
    app_id: string,
    created: string,
    last_used: string | null,
    last_ip: string | null
}

export interface RESTComment {
    id?: number,
    author?: number,
    author_email?: string,
    author_ip?: string,
    author_name?: string,
    author_url?:string,
    author_user_agent?: string,
    content: string | { rendered: string, raw: string },
    date?: string,
    date_gmt?: string,
    link?: string,
    parent?: number,
    post: number,
    status?: string,
    type?: string,
    author_avatar_urls?: object,
    meta?: object
}

const getLastWPPosts = (page:number, authorId?:string): Promise<{ posts:Array<RESTPost>, totalPages:number, totalPosts:number }> => {
    return new Promise((resolve, reject) => {
        const endPoint:string = baseURL + wpPostsEndpoint
            + "?page=" + page
            + "&per_page=10"
            + "&categories_exclude=7615"
            + (authorId === undefined ? '' : "&author=" + authorId);

        fetch(endPoint)
            .then( async (response:Response) => {
                return {
                    totalPages: parseInt(response.headers.get("x-wp-totalpages") ?? ""),
                    totalPosts: parseInt(response.headers.get("x-wp-total") ?? ""),
                    posts: await response.json()
                };
            })
            .then( (result) => {
                resolve(result);
            })
            .catch( (err) => {
                reject(err);
            });
    });
}

const getWPPost = (postId?:number) : Promise<{ posts:Array<RESTPost> }> => {

    return new Promise((resolve, reject) => {
        const endPoint:string = baseURL + wpPostsEndpoint + "/" + postId;

        fetch(endPoint)
            .then( async (response:Response) => {
                return {
                    posts: await response.json()
                }
            })
            .then( (result) => {
                resolve(result);
            })
            .catch( (err) => {
                reject(err);
            });
    });
}


const getTrendingWPPosts = (page:number): Promise<{ posts:Array<RESTPost>, totalPages:number, totalPosts:number  }> => {

    return new Promise((resolve, reject) => {
        const twentyfourHoursBefore:Date = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
        const endPoint:string = baseURL + wpPostsEndpoint
            + "?page=" + page
            + "&per_page=20"
            + "&categories_exclude=7615"
            + "&after=" + twentyfourHoursBefore.toISOString()
            + "&orderby=ratings_score"
            + "&order=desc"

        fetch(endPoint)
            .then( async (response:Response) => {
                return {
                    totalPages: parseInt(response.headers.get("x-wp-totalpages") ?? ""),
                    totalPosts: parseInt(response.headers.get("x-wp-total") ?? ""),
                    posts: await response.json()
                };
            })
            .then( (result) => {
                resolve(result);
            })
            .catch( (err) => {
                reject(err);
            });
    });

}

const ratePost = (userCredentials:UserCredentials, postId:number): Promise<{success:boolean, new_ratings_score:number}> => {

    const encodedCredentials:string = btoa(userCredentials.user_login + ":" + userCredentials.password);
    return new Promise((resolve, reject) => {
        const endPoint:string = baseURL + ratePostEndpoint + "/" + postId;

        fetch(endPoint, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Basic ${encodedCredentials}`
            }
        }).then( (result) => {
            if(result.status === 200){
                resolve(result.json());
            }else{
                reject(result.json());
            }
        }).catch( (err) => {
            reject(err);
        });
    })
}

const saveTemp = (bdDef:IBDDef, nonce:string): Promise<{URL:string}> => {

    return new Promise((resolve, reject) => {
        const endPoint:string = baseURL + editorEndpoint + "/savetemp";

        fetch(endPoint, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                definition: bdDef
            })
        }).then( async (response:Response) => {
            return await response.json();
        }).then( (result) => {
            resolve(result);
        }).catch( (err) => {
            reject(err);
        });
    });
}

export interface IPublishInfo {
    post_id?: number,
    author_id?: number,
    title: string,
    description : string,
    category: number,
    hidden: boolean,
    certified: boolean,
    verbi?: string,
    rebounded_bd_id?: string,
    definition: IBDDef
}

const createBD = (userCredentials: UserCredentials, bd:IPublishInfo): Promise<{status:string, posts: Post[], permalink:string}> => {

    const encodedCredentials:string = btoa(userCredentials.user_login + ":" + userCredentials.password);
    return new Promise((resolve, reject) => {
        const endPoint:string = baseURL + editorEndpoint + "/create";

        fetch(endPoint, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Basic ${encodedCredentials}`
            },
            body: JSON.stringify({
                bd: bd
            })
        }).then( (result) => {
            if(result.status === 200){
                resolve(result.json());
            }else{
                reject(result.json());
            }
        }).catch( (err) => {
            reject(err);
        });
    });
}

const getMyUserInfo = (userCredentials: UserCredentials): Promise<User> => {
    const encodedCredentials:string = btoa(userCredentials.user_login + ":" + userCredentials.password);
    return new Promise((resolve, reject) => {
        const endPoint:string = baseURL + wpUsersEndpoint + "/me";

        fetch(endPoint, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Basic ${encodedCredentials}`
            }
        }).then( (result) => {
            if(result.status === 200){
                resolve(result.json());
            }else{
                reject(result.json());
            }
        }).catch( (err) => {
            reject(err);
        });
    })
}

const getCurrentApplicationPassword = (userCredentials: UserCredentials): Promise<ApplicationPassword> => {
    const encodedCredentials:string = btoa(userCredentials.user_login + ":" + userCredentials.password);
    return new Promise((resolve, reject) => {
        const endPoint:string = baseURL + wpUsersEndpoint + "/me/application-passwords/introspect";

        fetch(endPoint, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Basic ${encodedCredentials}`
            }
        }).then( (result) => {
            if(result.status === 200){
                resolve(result.json());
            }else{
                reject(result.json());
            }
        }).catch( (err) => {
            reject(err);
        });
    })
}

const revokeApplicationPassword = (userCredentials: UserCredentials): Promise<{deleted:boolean,previous:ApplicationPassword}> => {
    const encodedCredentials:string = btoa(userCredentials.user_login + ":" + userCredentials.password);
    return new Promise((resolve, reject) => {
        const endPoint:string = baseURL + wpUsersEndpoint + "/me/application-passwords/" + userCredentials.uuid;

        fetch(endPoint, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Basic ${encodedCredentials}`
            }
        }).then( (result) => {
            if(result.status === 200){
                resolve(result.json());
            }else{
                reject(result.json());
            }
        }).catch( (err) => {
            reject(err);
        });
    })
}

const getComments = (postId: number): Promise<RESTComment[]> => {
    return new Promise((resolve, reject) => {
        const endPoint:string = baseURL + wpCommentsEndpoint + "?post=" + postId + "&orderby=date&order=asc";

        fetch(endPoint, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        }).then( (result) => {
            if(result.status === 200){
                resolve(result.json());
            }else{
                reject(result.json());
            }
        }).catch( (err) => {
            reject(err);
        });
    })
}
const createComment = (userCredentials: UserCredentials, comment: RESTComment): Promise<RESTComment> => {
    const encodedCredentials:string = btoa(userCredentials.user_login + ":" + userCredentials.password);
    return new Promise((resolve, reject) => {
        const endPoint:string = baseURL + wpCommentsEndpoint;

        fetch(endPoint, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Basic ${encodedCredentials}`
            },
            body: JSON.stringify(comment)
        }).then( (result) => {
            if(result.status === 201){
                resolve(result.json());
            }else{
                reject(result.json());
            }
        }).catch( (err) => {
            reject(err);
        });
    })
}

const wpApi = {
    getWPPost : getWPPost,
    getLastWPPosts : getLastWPPosts,
    getTrendingWPPosts : getTrendingWPPosts,

    getComments : getComments,
    createComment : createComment,

    ratePost : ratePost,

    saveTemp : saveTemp,
    publishBD : createBD,

    getMyUserInfo : getMyUserInfo,
    getCurrentApplicationPassword : getCurrentApplicationPassword,
    revokeApplicationPassword : revokeApplicationPassword
}

export default wpApi;