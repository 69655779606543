import React, {useContext, useEffect, useState} from "react";
import "./Liker.css";
import {LikesContext} from "../contexts/LikesContext";
import wpApi from "../services/wpApi";
import {UserCredentials} from "../services/wpAuth";
import {UserContext} from "../contexts/UserContext";

interface LikerProps {
    count: number,
    postID: number
}

const Liker:React.FC<LikerProps> = ( {count, postID} ) => {
    const [localCount, setLocalCount] = useState(count);
    const [rateOnGoing, setRateOnGoing] = useState(false);
    const {currentUser} = useContext(UserContext);
    const {likes, setLikes} = useContext(LikesContext);
    const [liked, setLiked] = useState(false);

    const toggleLike = () => {
        if(currentUser === null || rateOnGoing) return;

        setRateOnGoing(true);

        const storedCredentials : string | null = localStorage.getItem("user_credentials")
        if(storedCredentials !== null){
            const userCredentials : UserCredentials = JSON.parse(storedCredentials);
            wpApi.ratePost(userCredentials, postID).then((response) => {
                setLocalCount(response.new_ratings_score);

                let newLikes : number[];
                if(!liked){
                    newLikes = [
                        postID,
                        ...likes
                    ];
                }else{
                    newLikes = likes.filter( p => p !== postID);
                }
                if(newLikes.length > 100){
                    newLikes.pop();
                }
                setLikes(newLikes);
                localStorage.setItem("likes", JSON.stringify(newLikes));
            }).catch((reason:any) => {
                console.error("Unable to rate post: " + JSON.stringify(reason));
            }).finally(()=> {
                setRateOnGoing(false);
            })
        }
    }

    useEffect(() => {
        setLiked(likes && ( -1 !== likes.indexOf(postID) ));
    }, [likes, postID]);

    return (
        <button type={"button"} className={"Liker " + (liked ? "liked " : " ") + (rateOnGoing ? "busy " : " ")} onClick={() => toggleLike()}>
            <span className={"count"}>{localCount}</span> <span className={"heart"}>♥</span>
        </button>
    )
}

export default Liker;