import React from "react";
import Editor from "../components/editor/Editor";
import Header from "../components/Header";

const EditorPage : React.FC = () => {
    return (
        <div className={"Page"}>
            <Header/>
            <Editor/>
        </div>
    );
};

export default EditorPage;