import React from "react";
import "./Comments.css";
import {RESTComment} from "../services/wpApi";

interface CommentsProps {
    comments : RESTComment[]
}

const Comments:React.FC<CommentsProps> = ( {comments} ) => {

    return (
        <ul className={"Comments"}>
            {
                comments.map((comment => {
                    return (
                        <li key={comment.id}>
                            <span className={"comment_author"}>{comment.author_name}</span>
                            {
                                comment.date &&
                                <span className={"comment_date"}>
                                    <time dateTime={comment.date}>{new Date(comment.date).toLocaleString()}</time>
                                 </span>
                            }
                            <span className={"comment_content"} dangerouslySetInnerHTML={{
                                __html: (comment.content as {
                                    rendered: string
                                }).rendered
                            }}/>
                        </li>
                    );
                }))
            }
        </ul>
    )
}

export default Comments;