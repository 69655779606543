import React, {FormEvent, useState} from "react";
import "./PublishForm.css";
import {IBDDef} from "../BD";
import wpApi, {IPublishInfo, Post} from "../../services/wpApi";
import {UserCredentials} from "../../services/wpAuth";

interface PublishFormProps {
    bdDef: IBDDef
}

const PublishForm : React.FC<PublishFormProps> = ({bdDef}) => {
    const [busy, setBusy] = useState(false);
    const [publishedPostId, setPublishedPostId] = useState(-1);
    const [error, setError] = useState<string | null>(null);

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [category, setCategory] = useState(0);
    const [isVerbi, setIsVerbi] = useState(false);

    const onPublishFormSubmit = (e:FormEvent) => {
        e.preventDefault();

        const storedCredentials : string | null = localStorage.getItem("user_credentials")
        if(storedCredentials !== null) {
            const userCredentials: UserCredentials = JSON.parse(storedCredentials);

            let publishInfo : IPublishInfo = {
                category: category,
                certified: false,
                definition: bdDef,
                description: description,
                hidden: false,
                title: title,
            }

            wpApi.publishBD(userCredentials, publishInfo).then((response) => {
                const publishedPost : Post = response.posts[0];
                setPublishedPostId(publishedPost.ID);
                setError(null);
            }).catch((reason) => {
                setError(reason);
            }).finally(() => {
                setBusy(false);
            })
        }
        return false;
    }

    return (
        <div className={"PublishForm"}>
            <form onSubmit={onPublishFormSubmit}>
                <h2>Publier sur BirdsDessinés.fr</h2>
                <input type={"text"} placeholder={"Titre de votre BD*"} value={title} onChange={(e) => setTitle(e.target.value)}/>
                <textarea value={description} placeholder={"Courte description"} onChange={(e) => setDescription(e.target.value)}/>
                <div className={"footer"}>
                    {
                        publishedPostId === -1 &&
                        <button type={"submit"} disabled={busy || (title === "")}>Publier</button>
                    }
                    {
                        publishedPostId !== -1 &&
                        <div className={"alert alert-success"}>Votre BD a été publiée. </div>
                    }
                    {
                        error !== null &&
                        <div className={"alert alert-error"}>Erreur: {error}</div>
                    }
                </div>
            </form>
        </div>
    )
}

export default PublishForm;