import React from "react";
import "./Header.css";
import {Link, NavLink} from "react-router-dom";
import logo from "../assets/logo_birdsdessines.png";
import ConnectionButton from "./ConnectionButton";

interface HeaderProps {

}

const Header:React.FC<HeaderProps> = () => {
    return (
        <div className={"Header"}>
            <div className={"TopBar"}>
                <Link to={"/"}><img className={"logo"} src={logo} alt={"Logo Birds Dessinés"}/></Link>
                <ConnectionButton/>
            </div>

            <nav className={"TopNav"}>
                <ul>
                    <li><NavLink to={"/last"}>Le Fil</NavLink></li>
                    <li><NavLink to={"/top"}>Le Top</NavLink></li>
                    <li><NavLink to={"/editor"}>Éditeur</NavLink></li>
                </ul>
            </nav>
        </div>
    );
}

export default Header;