export interface IBDDef {
    [key:string]: any
}
export class BDDef implements IBDDef{
    background: string = "bluesky";
    vsep1: boolean = true;
    vsep2: boolean = true;
    bird1: { type: string } = {type:""};
    bird2: { type: string } = {type:""};
    bird3: { type: string } = {type:""};
    bird4: { type: string } = {type:""};
    bird5: { type: string } = {type:""};
    bird6: { type: string } = {type:""};
    bubble1: { type: string, text1:string, text2:string } = {type: "", text1:"", text2:""}
    bubble2: { type: string, text1:string, text2:string } = {type: "", text1:"", text2:""}
    bubble3: { type: string, text1:string, text2:string } = {type: "", text1:"", text2:""}
}

export class BD {
    author_id : string = "";
    title : string = "";
    description : string = "";
    category = "13";
    hidden = false;
    certified = false
    definition : IBDDef = new BDDef();
    comments : Array<Comment> = [];
}

export class Comment {
    comment_ID : string = "";
    comment_author : string = "";
    comment_content : string = "";
    comment_date : string = "";
}
