import React, {useContext} from "react";
import "./UserProfile.css";
import {UserCredentials} from "../services/wpAuth";
import wpApi from "../services/wpApi";
import {UserContext} from "../contexts/UserContext";
import {NavLink} from "react-router-dom";

interface UserProfileProps {
    onClose : () => void
}

const UserProfile : React.FC<UserProfileProps> = ({onClose}) => {
    const { currentUser, setCurrentUser } = useContext(UserContext);

    const onDisconnect = () => {
        const storedCredentials : string | null = localStorage.getItem("user_credentials")
        if(currentUser !== null && storedCredentials !== null){
            const userCredentials : UserCredentials = JSON.parse(storedCredentials);
            wpApi.revokeApplicationPassword(userCredentials).then((response) => {
                setCurrentUser(null);
                localStorage.removeItem("user_credentials")
            }).catch((reason:any) => {
                console.error("could not revoke password: " + reason.toString());
            })
        }
    }

    return (
        <div className={"UserProfile"}>
            <button className={"closeButton"} onClick={(e) => onClose()}><span className={"icon"}>✕</span>Fermer</button>
            <h1>Mon profil</h1>
            <ol className={"menu"}>
                <li><NavLink to={"/author/" + currentUser?.id}>Mes BDs</NavLink></li>
                <li><button type={"button"} onClick={(e) => onDisconnect()}>Me déconnecter</button></li>
            </ol>
        </div>
    );
}

export default UserProfile;