import React from "react";
import wpApi, {Post, RESTPost} from "../services/wpApi";
import Header from "../components/Header";
import Pager from "../components/Pager";
import BDPost from "../components/BDPost";
import {useLoaderData, useParams} from "react-router-dom";


export async function loader({ params } : { params:{page?:number, authorId?:string}}): Promise<{ totalPages:number, totalPosts:number, posts:RESTPost[] }> {
    const page:number = params.page ?? 1;
    const authorId:string = params.authorId ?? "";
    return await wpApi.getLastWPPosts(page, authorId);
}

const AuthorPage : React.FC = () => {
    const { page, authorId } = useParams();
    const currentPage:number = page ? parseInt(page) : 1;
    const { posts, totalPages, totalPosts } = useLoaderData() as { totalPages:number, totalPosts:number, posts:RESTPost[] };

    return (
        <div className={"Page authorPage postsList"}>
            <Header/>
            {
                currentPage > 1 && <Pager basePath={`/author/${authorId}`} currentPage={currentPage} totalPages={totalPages} totalPosts={totalPosts}/>
            }
            {
                posts.map( (post:RESTPost) => <BDPost key={post.id} post={post}/> )
            }
            <Pager basePath={`/author/${authorId}`} currentPage={currentPage} totalPages={totalPages} totalPosts={totalPosts}/>
        </div>
    )
}

export default AuthorPage;