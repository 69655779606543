import "./Library.css";

const BirdLibrary : { [key: string] : Array< {id:string} >} = {
    'birds' : [
        {id: 'birds_normal'},
        {id: 'birds_normal_left'},
        {id: 'birds_normal_right'},
        {id: 'birds_normal_down'},
        {id: 'birds_normal_down_left'},
        {id: 'birds_normal_down_right'},
        {id: 'birds_normal_up_left'},
        {id: 'birds_normal_up_right'},
        {id: 'birds_afraid'},
        {id: 'birds_afraid_left'},
        {id: 'birds_afraid_right'},
        {id: 'birds_afraid_speaking'},
        {id: 'birds_afraid_speaking_left'},
        {id: 'birds_afraid_speaking_right'},
        {id: 'birds_amused_left'},
        {id: 'birds_amused_right'},
        {id: 'birds_bending_left'},
        {id: 'birds_bending_right'},
        {id: 'birds_blind_left'},
        {id: 'birds_blind_right'},
        {id: 'birds_sing_left'},
        {id: 'birds_sing_right'},
        {id: 'birds_crazy'},
        {id: 'birds_excusing_left'},
        {id: 'birds_excusing_right'},
        {id: 'birds_go_left'},
        {id: 'birds_go_right'},
        {id: 'birds_flap_left'},
        {id: 'birds_flap_right'},
        {id: 'birds_haughty'},
        {id: 'birds_haughty_speaking'},
        {id: 'birds_laughing_left'},
        {id: 'birds_laughing_right'},
        {id: 'birds_listening_left'},
        {id: 'birds_listening_right'},
        {id: 'birds_love'},
        {id: 'birds_love_left'},
        {id: 'birds_love_right'},
        {id: 'birds_mdr_left'},
        {id: 'birds_mdr_right'},
        {id: 'birds_pissed'},
        {id: 'birds_pissed_speaking'},
        {id: 'birds_speaking'},
        {id: 'birds_speaking_left'},
        {id: 'birds_speaking_right'},
        {id: 'birds_whispering_left'},
        {id: 'birds_whispering_right'}
    ],
    'news' : [
        {id: 'news_behindnewspaper'},
        {id: 'news_normal'},
        {id: 'news_normal_left'},
        {id: 'news_normal_right'},
        {id: 'news_speaking_left'},
        {id: 'news_speaking_right'},
        {id: 'news_afraid'},
        {id: 'news_pissed'},
        {id: 'news_pissed_speaking'},
        {id: 'news_lady_behindnewspaper'},
        {id: 'news_lady_normal'},
        {id: 'news_lady_normal_left'},
        {id: 'news_lady_normal_right'},
        {id: 'news_lady_speaking'},
        {id: 'news_lady_speaking_left'},
        {id: 'news_lady_speaking_right'},
        {id: 'news_lady_afraid'}
    ],
    'lady' : [
        {id: 'lady_normal'},
        {id: 'lady_normal_left'},
        {id: 'lady_normal_right'},
        {id: 'lady_normal_up_left'},
        {id: 'lady_normal_up_right'},
        {id: 'lady_speaking'},
        {id: 'lady_speaking_left'},
        {id: 'lady_speaking_right'},
        {id: 'lady_speaking_smiling_left'},
        {id: 'lady_speaking_smiling_right'},
        {id: 'lady_smile_left'},
        {id: 'lady_smile_right'},
        {id: 'lady_mdr_left'},
        {id: 'lady_mdr_right'},
        {id: 'lady_shocked'},
        {id: 'lady_bending_left'},
        {id: 'lady_bending_right'},
        {id: 'lady_love_left'},
        {id: 'lady_love_right'},
        {id: 'lady_pissed'},
        {id: 'lady_pissed_speaking'},
        {id: 'lady_turning_left'},
        {id: 'lady_turning_right'},
        {id: 'lady_whispering_left'},
        {id: 'lady_whispering_right'},
        {id: 'lady_go_left'},
        {id: 'lady_go_right'}
    ],
    'baby' : [
        {id: 'baby_girl_normal_left'},
        {id: 'baby_girl_normal_right'},
        {id: 'baby_girl_speaking'},
        {id: 'baby_girl_speaking_left'},
        {id: 'baby_girl_speaking_right'},
        {id: 'baby_girl_ask'},
        {id: 'baby_girl_sulk'},
        {id: 'baby_girl_angry'},
        {id: 'baby_girl_amaze'},
        {id: 'baby_girl_crying'},
        {id: 'baby_boy_normal_left'},
        {id: 'baby_boy_normal_right'},
        {id: 'baby_boy_speaking'},
        {id: 'baby_boy_speaking_left'},
        {id: 'baby_boy_speaking_right'},
        {id: 'baby_boy_ask'},
        {id: 'baby_boy_sulk'},
        {id: 'baby_boy_angry'},
        {id: 'baby_boy_amaze'},
        {id: 'baby_boy_crying'}
    ],
    'old': [
        {id: 'old_oldbird_normal'},
        {id: 'old_oldbird_normal_left'},
        {id: 'old_oldbird_normal_right'},
        {id: 'old_oldbird_pissed'},
        {id: 'old_oldbird_pissed_speaking'},
        {id: 'old_oldbird_shocked'},
        {id: 'old_oldbird_speaking'},
        {id: 'old_oldbird_speaking_left'},
        {id: 'old_oldbird_speaking_right'},
        {id: 'old_oldlady_normal'},
        {id: 'old_oldlady_normal_left'},
        {id: 'old_oldlady_normal_right'},
        {id: 'old_oldlady_pissed'},
        {id: 'old_oldlady_pissed_speaking'},
        {id: 'old_oldlady_shocked'},
        {id: 'old_oldlady_speaking'},
        {id: 'old_oldlady_speaking_left'},
        {id: 'old_oldlady_speaking_right'}
    ],
    'corbac': [
        {id: 'corbac_normal'},
        {id: 'corbac_normal_left'},
        {id: 'corbac_normal_right'},
        {id: 'corbac_speaking'},
        {id: 'corbac_speaking_left'},
        {id: 'corbac_speaking_right'},
        {id: 'corbac_shocked'},
        {id: 'corbac_shocked_speaking'}
    ],
    'politic': [
        {id: 'politic_normal'},
        {id: 'politic_normal_left'},
        {id: 'politic_normal_right'},
        {id: 'politic_speaking'},
        {id: 'politic_speaking_left'},
        {id: 'politic_speaking_right'},
        {id: 'politic_angry'},
        {id: 'politic_shocked'}
    ],
    'hopital' : [
        {id: 'hopital_doctor_normal'},
        {id: 'hopital_doctor_normal_left'},
        {id: 'hopital_doctor_normal_right'},
        {id: 'hopital_doctor_speaking'},
        {id: 'hopital_doctor_speaking_left'},
        {id: 'hopital_doctor_speaking_right'},
        {id: 'hopital_doctor_turning_left'},
        {id: 'hopital_doctor_turning_right'},
        {id: 'hopital_doctor_afraid'},
        {id: 'hopital_doctor_afraid_speaking'},
        {id: 'hopital_nurse_normal'},
        {id: 'hopital_nurse_normal_left'},
        {id: 'hopital_nurse_normal_right'},
        {id: 'hopital_nurse_speaking'},
        {id: 'hopital_nurse_speaking_left'},
        {id: 'hopital_nurse_speaking_right'},
        {id: 'hopital_nurse_angry'},
        {id: 'hopital_nurse_angry_speaking'},
        {id: 'hopital_nurse_shocked'},
        {id: 'hopital_patient_normal'},
        {id: 'hopital_patient_normal_left'},
        {id: 'hopital_patient_normal_right'},
        {id: 'hopital_patient_speaking'},
        {id: 'hopital_patient_speaking_left'},
        {id: 'hopital_patient_speaking_right'},
        {id: 'hopital_patient_pissed'},
        {id: 'hopital_patient_pissed_speaking'},
        {id: 'hopital_patient_afraid'},
        {id: 'hopital_patient_afraid_speaking'}
    ],
    'foot': [
        {id: 'foot_arbitre_redcard_left'},
        {id: 'foot_arbitre_redcard_right'},
        {id: 'foot_arbitre_yellowcard_left'},
        {id: 'foot_arbitre_yellowcard_right'},
        {id: 'foot_ballon'},
        {id: 'foot_ballon_flat'},
        {id: 'foot_supporter_shouting'},
        {id: 'foot_supporter_singing_left'},
        {id: 'foot_supporter_singing_right'},
        {id: 'foot_supporter_shouting'},
        {id: 'foot_supporter_shocked'},
        {id: 'foot_joueur_france_normal'},
        {id: 'foot_joueur_france_normal_left'},
        {id: 'foot_joueur_france_normal_right'},
        {id: 'foot_joueur_france_speaking'},
        {id: 'foot_joueur_france_speaking_left'},
        {id: 'foot_joueur_france_speaking_right'},
        {id: 'foot_joueur_france_shocked'}
    ],
    'halloween' : [
        {id: 'halloween_citrouille_angry'},
        {id: 'halloween_citrouille_angry_speaking'},
        {id: 'halloween_citrouille_normal'},
        {id: 'halloween_citrouille_normal_right'},
        {id: 'halloween_citrouille_normal_left'},
        {id: 'halloween_citrouille_scarying'},
        {id: 'halloween_citrouille_shocked'},
        {id: 'halloween_citrouille_speaking'},
        {id: 'halloween_citrouille_speaking_right'},
        {id: 'halloween_citrouille_speaking_left'},
        {id: 'halloween_dracula_angry'},
        {id: 'halloween_dracula_angry_speaking'},
        {id: 'halloween_dracula_normal'},
        {id: 'halloween_dracula_normal_right'},
        {id: 'halloween_dracula_normal_left'},
        {id: 'halloween_dracula_scarying'},
        {id: 'halloween_dracula_shocked'},
        {id: 'halloween_dracula_speaking'},
        {id: 'halloween_dracula_speaking_right'},
        {id: 'halloween_dracula_speaking_left'},
        {id: 'halloween_frankenstein_angry'},
        {id: 'halloween_frankenstein_angry_speaking'},
        {id: 'halloween_frankenstein_normal'},
        {id: 'halloween_frankenstein_normal_right'},
        {id: 'halloween_frankenstein_normal_left'},
        {id: 'halloween_frankenstein_scarying'},
        {id: 'halloween_frankenstein_shocked'},
        {id: 'halloween_frankenstein_speaking'},
        {id: 'halloween_frankenstein_speaking_right'},
        {id: 'halloween_frankenstein_speaking_left'},
        {id: 'halloween_sorciere_angry'},
        {id: 'halloween_sorciere_angry_speaking'},
        {id: 'halloween_sorciere_normal'},
        {id: 'halloween_sorciere_normal_right'},
        {id: 'halloween_sorciere_normal_left'},
        {id: 'halloween_sorciere_scarying'},
        {id: 'halloween_sorciere_shocked'},
        {id: 'halloween_sorciere_speaking'},
        {id: 'halloween_sorciere_speaking_right'},
        {id: 'halloween_sorciere_speaking_left'}
    ],
    'noel' : [
        {id: 'noel_cerf_angry'},
        {id: 'noel_cerf_normal'},
        {id: 'noel_cerf_normal_left'},
        {id: 'noel_cerf_normal_right'},
        {id: 'noel_cerf_shocked'},
        {id: 'noel_cerf_speaking'},
        {id: 'noel_cerf_speaking_left'},
        {id: 'noel_cerf_speaking_right'},
        {id: 'noel_cerf_tired'},
        {id: 'noel_gift_giving_left'},
        {id: 'noel_gift_giving_right'},
        {id: 'noel_gift_opening'},
        {id: 'noel_gift_opening_angry'},
        {id: 'noel_gift_opening_speaking'},
        {id: 'noel_party_normal'},
        {id: 'noel_party_normal_left'},
        {id: 'noel_party_normal_right'},
        {id: 'noel_party_speaking'},
        {id: 'noel_party_speaking_left'},
        {id: 'noel_party_speaking_right'},
        {id: 'noel_party_throwing_left'},
        {id: 'noel_party_throwing_right'},
        {id: 'noel_santa_angry'},
        {id: 'noel_santa_normal'},
        {id: 'noel_santa_normal_left'},
        {id: 'noel_santa_normal_right'},
        {id: 'noel_santa_shocked'},
        {id: 'noel_santa_speaking'},
        {id: 'noel_santa_speaking_left'},
        {id: 'noel_santa_speaking_right'},
        {id: 'noel_santa_tired'},
        {id: 'noel_winter_boy_angry'},
        {id: 'noel_winter_boy_asking'},
        {id: 'noel_winter_boy_crying'},
        {id: 'noel_winter_boy_normal_left'},
        {id: 'noel_winter_boy_normal_right'},
        {id: 'noel_winter_boy_speaking'},
        {id: 'noel_winter_boy_speaking_left'},
        {id: 'noel_winter_boy_speaking_right'},
        {id: 'noel_winter_boy_sulk'},
        {id: 'noel_winter_boy_throwing_left'},
        {id: 'noel_winter_boy_throwing_right'},
        {id: 'noel_winter_girl_angry'},
        {id: 'noel_winter_girl_asking'},
        {id: 'noel_winter_girl_crying'},
        {id: 'noel_winter_girl_normal_left'},
        {id: 'noel_winter_girl_normal_right'},
        {id: 'noel_winter_girl_speaking'},
        {id: 'noel_winter_girl_speaking_left'},
        {id: 'noel_winter_girl_speaking_right'},
        {id: 'noel_winter_girl_sulk'},
        {id: 'noel_winter_girl_throwing_left'},
        {id: 'noel_winter_girl_throwing_right'}
    ],
    'police': [
        {id: 'police_officer_normal'},
        {id: 'police_officer_normal_left'},
        {id: 'police_officer_normal_right'},
        {id: 'police_officer_speaking'},
        {id: 'police_officer_speaking_left'},
        {id: 'police_officer_speaking_right'},
        {id: 'police_officer_shocked'},
        {id: 'police_chief_normal'},
        {id: 'police_chief_normal_left'},
        {id: 'police_chief_normal_right'},
        {id: 'police_chief_speaking'},
        {id: 'police_chief_speaking_left'},
        {id: 'police_chief_speaking_right'},
        {id: 'police_chief_shocked'}
    ],
    'summer': [
        {id: 'summer_birds_normal'},
        {id: 'summer_birds_normal_left'},
        {id: 'summer_birds_normal_right'},
        {id: 'summer_birds_speaking'},
        {id: 'summer_birds_speaking_left'},
        {id: 'summer_birds_speaking_right'},
        {id: 'summer_birds_shocked'},
        {id: 'summer_birds_pissed'},
        {id: 'summer_birds_pissed_speaking'},
        {id: 'summer_birds_tired'},
        {id: 'summer_birds_tired_speaking'},
        {id: 'summer_lady_normal'},
        {id: 'summer_lady_normal_left'},
        {id: 'summer_lady_normal_right'},
        {id: 'summer_lady_speaking'},
        {id: 'summer_lady_speaking_left'},
        {id: 'summer_lady_speaking_right'},
        {id: 'summer_lady_shocked'},
        {id: 'summer_lady_sleeping'},
        {id: 'summer_baby_normal'},
        {id: 'summer_baby_normal_left'},
        {id: 'summer_baby_normal_right'},
        {id: 'summer_baby_speaking'},
        {id: 'summer_baby_speaking_right'},
        {id: 'summer_baby_speaking_left'},
        {id: 'summer_baby_pissed'},
        {id: 'summer_baby_shocked'},
        {id: 'summer_baby_angry'},
        {id: 'summer_baby_crying'}
    ],
    'giletsjaunes': [
        {id: 'giletsjaunes_birds_normal'},
        {id: 'giletsjaunes_birds_normal_left'},
        {id: 'giletsjaunes_birds_normal_right'},
        {id: 'giletsjaunes_birds_speaking'},
        {id: 'giletsjaunes_birds_speaking_left'},
        {id: 'giletsjaunes_birds_speaking_right'},
        {id: 'giletsjaunes_birds_shocked'},
        {id: 'giletsjaunes_birds_pissed'},
        {id: 'giletsjaunes_birds_pissed_speaking'},
        {id: 'giletsjaunes_lady_normal'},
        {id: 'giletsjaunes_lady_normal_left'},
        {id: 'giletsjaunes_lady_normal_right'},
        {id: 'giletsjaunes_lady_speaking'},
        {id: 'giletsjaunes_lady_speaking_left'},
        {id: 'giletsjaunes_lady_speaking_right'},
        {id: 'giletsjaunes_lady_shocked'},
        {id: 'giletsjaunes_lady_pissed'},
        {id: 'giletsjaunes_lady_pissed_speaking'}
    ],
    'masques': [
        {id: 'masques_birds_normal'},
        {id: 'masques_birds_normal_left'},
        {id: 'masques_birds_normal_right'},
        {id: 'masques_birds_turning_left'},
        {id: 'masques_birds_turning_right'},
        {id: 'masques_birds_shocked'},
        {id: 'masques_birds_pissed'},
        {id: 'masques_lady_normal'},
        {id: 'masques_lady_normal_left'},
        {id: 'masques_lady_normal_right'},
        {id: 'masques_lady_turning_left'},
        {id: 'masques_lady_turning_right'},
        {id: 'masques_lady_shocked'},
        {id: 'masques_lady_pissed'},
        {id: 'masques_doctor_normal'},
        {id: 'masques_doctor_normal_left'},
        {id: 'masques_doctor_normal_right'},
        {id: 'masques_doctor_turning_left'},
        {id: 'masques_doctor_turning_right'},
        {id: 'masques_doctor_shocked'},
        {id: 'masques_nurse_normal'},
        {id: 'masques_nurse_normal_left'},
        {id: 'masques_nurse_normal_right'},
        {id: 'masques_nurse_shocked'},
        {id: 'masques_nurse_pissed'},
    ]
};

const BubbleLibrary : { [key: string] : Array< {id:string, maxLines:Array<number>} >} = {
    'bubble' : [
        {id: 'bubbles_little_left', maxLines: [2]},
        {id: 'bubbles_little_right', maxLines: [2]},
        {id: 'bubbles_big_left', maxLines: [4]},
        {id: 'bubbles_big_right', maxLines: [4]},
        {id: 'bubbles_thought_left', maxLines: [2]},
        {id: 'bubbles_thought_right', maxLines: [2]},
        {id: 'bubbles_left-right', maxLines: [2,2]},
        {id: 'bubbles_right-left', maxLines: [2,2]},
        {id: 'bubbles_full', maxLines: [5]},
        {id: 'bubbles_heart', maxLines: []},
        {id: 'bubbles_hearts', maxLines: []},
    ]
};

const BackgroundLibrary : { [key: string] : Array< {id:string} >} = {
    'standard' : [
        {id: 'bluesky'},
        {id: 'noel_day'},
        {id: 'noel_night'},
        {id: 'halloween_1'},
        {id: 'halloween_2'},
        {id: 'halloween_3'},
        {id: 'summer'},
    ],
    'square' : [
        {id: 'sq_bluesky'}
    ]
};


export {BirdLibrary, BubbleLibrary, BackgroundLibrary};