import React, {useCallback, useContext, useEffect, useState} from "react";
import "./Exporter.css";
import {IBDDef} from "../BD";
import wpApi from "../../services/wpApi";
import PublishForm from "./PublishForm";
import {UserContext} from "../../contexts/UserContext";

interface ExporterProps {
    bdDef : IBDDef,
    onClose : () => void
}

const Exporter : React.FC<ExporterProps> = ({bdDef, onClose}) => {
    const [bdURL, setBDURL] = useState<string | null>(null);
    const {currentUser} = useContext(UserContext);

    const share = async () => {
        try {
            const {URL} = await wpApi.saveTemp(bdDef, "cool");
            const bdBlob = await fetch(URL).then((response)=> {
                return response.blob();
            });
            const sharedData = {
                files: [
                    new File([bdBlob], 'bd.png', {
                        type: bdBlob.type
                    })
                ],
                title: "Ma Birds Dessinés",
                text: "Lisez ma BD créée sur https://www.birdsdessines.fr"
            };
            if(navigator.canShare(sharedData)){
                await navigator.share(sharedData);
            }
        }catch(error:any){
            console.error(error.name, error.message)
        }
    }

    const download = useCallback(async () => {
        try {
            const {URL} = await wpApi.saveTemp(bdDef, "cool")
            setBDURL( URL );

        }catch(error:any){
            console.error(error.name, error.message)
        }
    }, [bdDef]);

    useEffect(() => {
        download().catch(console.error);
    }, [download]);

    return (
        <div className={"Exporter"}>
            <div className={"modal"}>
                <div className={"header"}>
                    <h2>Votre BD</h2>
                    <button className={"closeButton"} onClick={(e) => onClose()}><span className={"icon"}>✕</span>Fermer
                    </button>
                    {
                        (navigator.share !== undefined) &&
                        <button type={"button"} onClick={(e) => share()}>Partager</button>
                    }
                    {
                        (bdURL !== null) &&
                        <a className={"button"} href={bdURL} download={"Ma BD BirdsDessines.png"}>Télécharger</a>
                    }
                </div>
                <div className={"bdPreview"}>
                    {
                        (bdURL !== null) &&
                        <img className={"bd"} src={bdURL} alt={"BD"}/>
                    }
                </div>
                {
                    currentUser !== null &&
                    <PublishForm bdDef={bdDef}/>
                }
            </div>
        </div>
    )
}

export default Exporter;