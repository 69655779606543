import React, {useCallback, useEffect, useState} from "react";
import wpApi, {RESTComment, RESTPost} from "../services/wpApi";
import "./BDPost.css";
import Comments from "./Comments";
import Liker from "./Liker";
import {Link} from "react-router-dom";
import CommentForm from "./CommentForm";

interface BDPostProps {
    post:RESTPost
}

const BDPost:React.FC<BDPostProps> = ( {post} ) => {
    const [comments, setComments] = useState<RESTComment[]>([]);

    let postLocalDate:string = '';
    if(post.date){
        postLocalDate = new Date(post.date).toLocaleString();
    }

    const refreshComments = useCallback(async () => {
        const newComments : RESTComment[] = await wpApi.getComments(post.id);
        setComments(newComments);
    }, [post]);

    const onCommentCreated = async () => {
        await refreshComments();
    }

    useEffect( () => {
        refreshComments().catch(console.error);
    }, [refreshComments]);

    return (
      <article className={"BDPost"} key={post.id}>
          <section className={"header"}>
              <Liker count={post.meta.ratings_score} postID={post.id}/>
              <h1 dangerouslySetInnerHTML={{__html : post.title.rendered}} />
              <p className={"details"}>
                  Par&nbsp;
                  <Link to={`/author/${post.author}`} className={"author"}>
                      <span className={"avatar"}>
                          <img src={post.author_avatar} alt={"avatar"} width={"24"} height={"24"} loading={"lazy"}/>
                      </span>
                      &nbsp;<b>{post.author_name}</b>
                  </Link>
                  &nbsp;le <time dateTime={post.date ?? undefined}>{postLocalDate}</time>
              </p>
          </section>
          <section className={"bd"}>
              <img src={post.meta["large-image"]} alt={post.title.raw} loading={"lazy"}/>
          </section>

          <section className={"comments"}>
              {
                  comments.length > 0 &&
                  <Comments comments={comments}/>
              }
              <CommentForm postId={post.id} onCommentCreated={onCommentCreated}/>
          </section>
      </article>
    );
}

export default BDPost;