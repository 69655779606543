import React from "react";
import wpApi, {RESTPost} from "../services/wpApi";
import {useLoaderData} from "react-router-dom";
import BDPost from "../components/BDPost";
import Header from "../components/Header";

export async function loader({ params } : { params: {postId?:number} }): Promise<{ posts:Array<RESTPost> }> {
    const postId:number | undefined = params.postId;
    return await wpApi.getWPPost(postId);
}

const PostPage : React.FC = () => {
    const { posts } = useLoaderData() as { posts:Array<RESTPost> };

    return (
        <div className={"Page PostPage postsList"}>
            <Header/>
            {
                posts.map( (post:RESTPost) => <BDPost key={post.id} post={post}/> )
            }
        </div>
    );
};

export default PostPage;