import React, {useState} from "react";

import "./Galleries.css";
import {BackgroundLibrary, BirdLibrary, BubbleLibrary} from "./Library";

interface GalleryProps {
    selectedGallery:string,
    onSelectItem: (itemId:string) => void,
}

const Galleries:React.FC<GalleryProps> = ({selectedGallery, onSelectItem}) => {

    const [selectedSubGallery, setSelectedSubGallery] = useState('birds');

    const toClasses = (i:string) : string => {
        return (i ? i.split('_').join(' ') : '');
    }

    return (
        <div className={"Galleries"}>
            {
                selectedGallery === 'bird' &&
                    <nav>
                    {
                        Object.keys(BirdLibrary).map( (galleryId) => {
                            return (
                                <div key={galleryId} className={`icon ${galleryId}-icon ${selectedSubGallery === galleryId ? 'selected':''}`}
                                    onClick={(e) => setSelectedSubGallery(galleryId)}>
                                </div>
                            )
                        })
                    }
                    </nav>
            }
            {
                selectedGallery === 'bird' &&
                <div className={`gallery ${selectedSubGallery}`}>
                    <div key={"unknown"} className="thumb birds unknown"
                         onClick={(e) => onSelectItem('')}/>
                    {
                        BirdLibrary[selectedSubGallery].map( ( item ) => {
                            return (
                                <div key={item.id} className={`thumb ${toClasses(item.id)}`}
                                    onClick={(e) => onSelectItem(item.id)}/>
                            );
                        })
                    }
                </div>
            }
            {
                selectedGallery === 'bubble' &&
                <div className={`gallery bubble-gallery`}>
                    <div key={"unknown"} className="thumb bubbles unknown"
                         onClick={(e) => onSelectItem('')}/>
                    {
                        BubbleLibrary.bubble.map( ( item ) => {
                            return (
                                <div key={item.id} className={`thumb ${toClasses(item.id)}`}
                                     onClick={(e) => onSelectItem(item.id)}/>
                            );
                        })
                    }
                </div>
            }
            {
                selectedGallery === 'background' &&
                <div className={`gallery background-gallery`}>
                    <div className={"sub-gallery"}>
                        <h2>3 cases:</h2>
                        {
                            BackgroundLibrary.standard.map((item) => {
                                return (
                                    <div key={item.id} className={`thumb ${item.id}`}
                                         onClick={(e) => onSelectItem(item.id)}/>
                                );
                            })
                        }
                    </div>
                    <div className={"sub-gallery"}>
                        <h2>1 case:</h2>
                        {
                            BackgroundLibrary.square.map((item) => {
                                return (
                                    <div key={item.id} className={`thumb ${item.id}`}
                                         onClick={(e) => onSelectItem(item.id)}/>
                                );
                            })
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export default Galleries;