import React from "react";
import wpApi, {RESTPost} from "../services/wpApi";
import {useLoaderData, useParams} from "react-router-dom";
import BDPost from "../components/BDPost";
import Pager from "../components/Pager";
import Header from "../components/Header";

export async function loader({ params } : { params:{page?:number}}): Promise<{ totalPages:number, totalPosts:number, posts:RESTPost[] }> {
    const page:number = params.page ?? 1;
    return await wpApi.getLastWPPosts(page);
}

const LastPostsPage : React.FC = () => {
    const { page } = useParams();
    const currentPage:number = page ? parseInt(page) : 1;
    const { posts, totalPages, totalPosts } = useLoaderData() as { totalPages:number, totalPosts:number, posts:RESTPost[] };

    return (
      <div className={"Page lastPostsPage postsList"}>
          <Header/>
          {
              currentPage > 1 && <Pager basePath={"/last"} currentPage={currentPage} totalPages={totalPages} totalPosts={totalPosts}/>
          }
          {
              posts.map( (post:RESTPost) => <BDPost key={post.id} post={post}/> )
          }
          <Pager basePath={"/last"} currentPage={currentPage} totalPages={totalPages} totalPosts={totalPosts}/>
      </div>
    );
};

export default LastPostsPage;