import React, {useEffect, useState} from "react";
import Scene from "./Scene";
import {BD} from "../BD";
import "./Editor.css";
import Galleries from "./Galleries";
import Exporter from "./Exporter";

interface EditorProps {

}

const Editor:React.FC<EditorProps> = () => {
    const bd:BD = new BD();

    const [bdDef, setBdDef] = useState(bd.definition);
    const [selectedHolder, setSelectedHolder] = useState('bird1');
    const [selectedGallery, setSelectedGallery] = useState('bird');
    const [sceneFormat, setSceneFormat] = useState('standard');
    const [showExporter, setShowExporter] = useState(false);

    useEffect(() => {
        if( selectedHolder.startsWith('bird') ) setSelectedGallery('bird');
        else if( selectedHolder.startsWith('bubble') ) setSelectedGallery('bubble');
        else setSelectedGallery('background');
    }, [selectedHolder]);

    const handleGalleryClick = (itemId:string) => {
        changeElement(selectedHolder, {type:itemId});
    }

    const changeElement = (elementId:string, newValue:any) => {
        switch (elementId) {
            case 'separator' : {
                setBdDef({
                    ...bdDef,
                    [newValue.sepId]: newValue.value
                })
                break;
            }
            case 'background': {
                const itemId: string = newValue.type as string;
                if(itemId.startsWith('sq_')){
                    setSceneFormat('square');
                }else{
                    setSceneFormat('standard');
                }
                setBdDef({
                    ...bdDef,
                    background: itemId
                })
                break;
            }
            default: {
                setBdDef({
                    ...bdDef,
                    [selectedHolder]: {
                        ...bdDef[selectedHolder],
                        ...newValue
                    }
                });
                break;
            }
        }
    }

    return (
        <div id={"bd_editor"} className={"Editor"}>
            <div className={"tools"}>
                <button type={"button"} onClick={(e) => setShowExporter(true)}>Exporter</button>
            </div>
            <div className={"SceneWrapper " + sceneFormat}>
                <Scene format={sceneFormat} bdDef={bdDef} selectedHolder={selectedHolder} onSelectHolder={(h) => setSelectedHolder(h)} onChangeElement={changeElement}/>
            </div>
            <Galleries selectedGallery={selectedGallery} onSelectItem={handleGalleryClick}/>
            {
                showExporter &&
                <Exporter bdDef={bdDef} onClose={() => setShowExporter(false)}/>
            }
        </div>
    );
}

export default Editor;