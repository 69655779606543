import React, {useContext, useState} from "react";
import "./ConnectionButton.css";
import {UserContext} from "../contexts/UserContext";
import wpAuth from "../services/wpAuth";
import UserProfile from "./UserProfile";

interface ConnectionButtonProps {

}

const ConnectionButton : React.FC<ConnectionButtonProps> = () => {
    const { currentUser } = useContext(UserContext);
    const [isProfileOpen, setIsProfileOpen] = useState(false);
    const authUrl : string = wpAuth.authorizationUrl;


    if(!currentUser) {
        return <div className={"ConnectionButton"}><a href={authUrl}>Se connecter</a></div>;
    }

    return (
        <div className={"ConnectionButton connected"}>
            <button type={"button"} onClick={() => setIsProfileOpen(true)}>
                <img className={"avatar"} alt={"avatar"} src={currentUser.avatar_urls["24"]}/>
                <span className={"displayName"}>{currentUser.name}</span>
            </button>
            { isProfileOpen && <UserProfile onClose={() => setIsProfileOpen(false)}/> }
        </div>
    );
}

export default ConnectionButton;