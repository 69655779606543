import React from "react";
import {Link} from "react-router-dom";
import "./Pager.css";

interface PagerProps {
    basePath:string,
    currentPage:number,
    totalPages:number,
    totalPosts:number
}

const Pager:React.FC<PagerProps> = ({basePath, currentPage, totalPages, totalPosts}) => {
    return (
        <div className={"Pager"}>
            {
                (currentPage > 1) && <Link className={"PageLink back"} to={basePath  + "/" + (currentPage>1 ? currentPage-1 : "")}>Page précédente</Link>
            }
            {
                (currentPage < totalPages) && <Link className={"PageLink forward"} to={basePath + "/" + (currentPage+1)}>Page suivante</Link>
            }

        </div>
    );
}

export default Pager;