import React from "react";
import "./Scene.css";
import {BubbleLibrary} from "./Library";
import {IBDDef} from "../BD";
import BubbleTextArea from "./BubbleTextArea";

interface SceneProps {
    format : string,
    bdDef : IBDDef,
    selectedHolder: string,
    onSelectHolder: (h:string) => void,
    onChangeElement: (elementId:string, newValue:any) => void
}

const Scene:React.FC<SceneProps> = ({ format='standard', bdDef, selectedHolder, onSelectHolder, onChangeElement}) => {

    const toggleSeparator = (vsep:string) => {
        const newValue:boolean = bdDef[vsep] as boolean;
        onChangeElement('separator', {
            sepId: vsep,
            value: !newValue
    });
    }

    const toClasses = (i:string) : string => {
        return (i ? i.split('_').join(' ') : '');
    }

    const getMaxLines = (item:string, idx:number) : number => {
        if (item === "") return -1;
        const bubble = BubbleLibrary.bubble.find(b => b.id === item);
        return bubble ? bubble.maxLines[idx-1] : -1;
    }

    return (
        <div id={"scene"} className={"Scene " + format}>
            <div id={"background"} className={`bg ${bdDef.background}`}></div>
            <button className={`background_edit_btn ${selectedHolder === 'background' ? 'selected':''}`}
                    onClick={(e) => onSelectHolder('background')}></button>
            <div id="vsep1" className={`vsep white ${!bdDef.vsep1 ? 'transparent':''}`}
               onClick={(e) => toggleSeparator('vsep1')}></div>
            <div id="vsep2" className={`vsep white ${!bdDef.vsep2 ? 'transparent':''}`}
               onClick={(e) => toggleSeparator('vsep2')}></div>

            <div id="bird1" className={`holder bird-holder ${toClasses(bdDef.bird1.type)} ${selectedHolder === 'bird1' ? 'selected':''}`}
               onClick={(e) => onSelectHolder('bird1')}></div>
            <div id="bird2" className={`holder bird-holder ${toClasses(bdDef.bird2.type)} ${selectedHolder === 'bird2' ? 'selected':''}`}
               onClick={(e) => onSelectHolder('bird2')}></div>
            <div id="bird3" className={`holder bird-holder ${toClasses(bdDef.bird3.type)} ${selectedHolder === 'bird3' ? 'selected':''}`}
               onClick={(e) => onSelectHolder('bird3')}></div>
            <div id="bird4" className={`holder bird-holder ${toClasses(bdDef.bird4.type)} ${selectedHolder === 'bird4' ? 'selected':''}`}
               onClick={(e) => onSelectHolder('bird4')}></div>
            <div id="bird5" className={`holder bird-holder ${toClasses(bdDef.bird5.type)} ${selectedHolder === 'bird5' ? 'selected':''}`}
               onClick={(e) => onSelectHolder('bird5')}></div>
            <div id="bird6" className={`holder bird-holder ${toClasses(bdDef.bird6.type)} ${selectedHolder === 'bird6' ? 'selected':''}`}
               onClick={(e) => onSelectHolder('bird6')}></div>

            <div id="bubble1"
                 className={`holder bubble-holder ${toClasses(bdDef.bubble1.type)} ${selectedHolder === 'bubble1' ? 'selected':''}`}
                 onClick={(e) => onSelectHolder('bubble1')}>

                <div className={`text-wrapper ${toClasses(bdDef.bubble1.type)}`}>
                    <BubbleTextArea className="ta1" maxLines={getMaxLines(bdDef.bubble1.type, 1)}
                                    value={bdDef.bubble1.text1}
                                    onChange={(text)=> onChangeElement('bubble1', {text1: text})}/>
                    <BubbleTextArea className="ta2" maxLines={getMaxLines(bdDef.bubble1.type, 2)}
                                    value={bdDef.bubble1.text2}
                                    onChange={(text)=> onChangeElement('bubble1', {text2: text})}/>
                </div>

            </div>
            <div id="bubble2"
                 className={`holder bubble-holder ${toClasses(bdDef.bubble2.type)} ${selectedHolder === 'bubble2' ? 'selected':''}`}
                 onClick={(e) => onSelectHolder('bubble2')}>

                <div className={`text-wrapper ${toClasses(bdDef.bubble2.type)}`}>
                    <BubbleTextArea className="ta1" maxLines={getMaxLines(bdDef.bubble2.type, 1)}
                                    value={bdDef.bubble2.text1}
                                    onChange={(text)=> onChangeElement('bubble2', {text1: text})}/>
                    <BubbleTextArea className="ta2" maxLines={getMaxLines(bdDef.bubble2.type, 2)}
                                    value={bdDef.bubble2.text2}
                                    onChange={(text)=> onChangeElement('bubble2', {text2: text})}/>
                </div>

            </div>
            <div id="bubble3"
                 className={`holder bubble-holder ${toClasses(bdDef.bubble3.type)} ${selectedHolder === 'bubble3' ? 'selected':''}`}
                 onClick={(e) => onSelectHolder('bubble3')}>

                <div className={`text-wrapper ${toClasses(bdDef.bubble3.type)}`}>
                    <BubbleTextArea className="ta1" maxLines={getMaxLines(bdDef.bubble3.type, 1)}
                                    value={bdDef.bubble3.text1}
                                    onChange={(text)=> onChangeElement('bubble3', {text1: text})}/>
                    <BubbleTextArea className="ta2" maxLines={getMaxLines(bdDef.bubble3.type, 2)}
                                    value={bdDef.bubble3.text2}
                                    onChange={(text)=> onChangeElement('bubble3', {text2: text})}/>
                </div>

            </div>
        </div>
    )
}

export default Scene;