import React, {FormEvent, useContext, useState} from "react";
import "./CommentForm.css";
import {UserContext} from "../contexts/UserContext";
import wpApi, {RESTComment} from "../services/wpApi";
import {UserCredentials} from "../services/wpAuth";

interface CommentFormProps {
    postId: number,
    onCommentCreated: (comment:RESTComment) => void
}

const CommentForm : React.FC<CommentFormProps> = ({postId, onCommentCreated}) => {
    const [sending, setSending] = useState(false);
    const [content, setContent] = useState("");
    const {currentUser} = useContext(UserContext);

    const onSubmit = (event:FormEvent) => {
        event.preventDefault();
        setSending(true);

        const storedCredentials : string | null = localStorage.getItem("user_credentials")
        if(storedCredentials !== null) {
            const userCredentials: UserCredentials = JSON.parse(storedCredentials);
            const restComment : RESTComment = {
                content: content,
                post: postId
            }
            wpApi.createComment(userCredentials, restComment).then((comment) => {
                onCommentCreated(comment);
                setContent("");
            })
            .catch((reason:any) => {
                console.error("Unable to create comment: " + JSON.stringify(reason));
            }).finally(()=> {
                setSending(false);
            })
        }
        return false;
    }

    if(currentUser === null){
        return null;
    }

    return (
        <form id={"comment-form"} className={"CommentForm"} onSubmit={onSubmit}>
            <input placeholder={"Laisser un commentaire"} autoComplete={"off"} disabled={sending}
                   value={content} onChange={(e) => setContent(e.target.value)}/>
            <button type={"submit"} disabled={content === ""}>Envoyer</button>
        </form>
    )
}

export default CommentForm;