
const app_name : string = "Application web Birds Dessinés";
const app_uuid : string = "aa8098e6-253d-4d12-848f-b31bb174fff9";
const success_url : string = "https://www.birdsdessines.fr/app?do=auth";
const reject_url : string = "https://www.birdsdessines.fr/app?do=auth";
const authorization_url : string = "https://www.birdsdessines.fr/wp-admin/authorize-application.php";

export type UserCredentials = {
    uuid:string | null;
    site_url: string | null;
    user_login: string | null;
    password: string | null;
}

const wpAuth  = {
    authorizationUrl : authorization_url + "?app_name=" + app_name + "&app_id=" + app_uuid + "&success_url=" + success_url + "&reject_url=" + reject_url
}

export default wpAuth;