import React, {useEffect, useState} from 'react';
import './App.css';
import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom";
import LastPostsPage, {loader as getLastPostsLoader} from "./pages/LastPostsPage";
import AuthorPage, {loader as getAuthorLoader}  from "./pages/AuthorPage";
import TopPostsPage, {loader as getTrendingPostsLoader} from "./pages/TopPostsPage";
import PostPage, {loader as getPostLoader} from "./pages/PostPage";
import EditorPage from "./pages/EditorPage";
import ErrorPage from "./pages/ErrorPage";
import {UserContext} from "./contexts/UserContext";
import wpApi, {User} from "./services/wpApi";
import {UserCredentials} from "./services/wpAuth";
import {LikesContext} from "./contexts/LikesContext";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Navigate to={"/last/1"}/>
    },
    {
        path: "/last/:page?",
        element: <LastPostsPage />,
        errorElement: <ErrorPage />,
        loader: getLastPostsLoader
    },
    {
        path: "/author/:authorId/:page?",
        element: <AuthorPage />,
        errorElement: <ErrorPage />,
        loader: getAuthorLoader
    },
    {
        path: "/top/:page?",
        element: <TopPostsPage />,
        errorElement: <ErrorPage />,
        loader: getTrendingPostsLoader
    },
    {
        path: "/post/:postId",
        element: <PostPage/>,
        errorElement: <ErrorPage />,
        loader: getPostLoader
    },
    {
        path: "/editor",
        element: <EditorPage />,
        errorElement: <ErrorPage />,
    }
], {
    basename: process.env.REACT_APP_ROUTER_BASENAME,
})

function App() {
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const userContextValue = { currentUser, setCurrentUser };
    const [likes, setLikes] = useState<number[]>([]);
    const likesContextValue = { likes, setLikes };

    const initLikes = () => {
        const storedLikes : string | null = localStorage.getItem("likes");
        if(storedLikes !== null){
            setLikes(JSON.parse(storedLikes) as Array<number>);
        }else{
            localStorage.setItem("likes", JSON.stringify(likes));
        }
    }

    const initCurrentUser = () => {
        const storedCredentials : string | null = localStorage.getItem("user_credentials")
        if(currentUser === null && storedCredentials !== null){
            const userCredentials : UserCredentials = JSON.parse(storedCredentials);
            wpApi.getMyUserInfo(userCredentials).then((response) => {
                setCurrentUser(response);
            }).catch((reason:any) => {
                console.error("Unable to get user info from credentials: " + JSON.stringify(userCredentials));
            })
        }
    }

    const handleAuthentication = () => {
        const searchParams : URLSearchParams = new URLSearchParams(window.location.search);
        if(searchParams.get("do") === "auth"){
            let newUserCredentials : UserCredentials = {
                uuid: null,
                site_url: searchParams.get("site_url"),
                user_login: searchParams.get("user_login"),
                password: searchParams.get("password"),
            }
            wpApi.getCurrentApplicationPassword(newUserCredentials).then((appPassword) => {
                newUserCredentials.uuid = appPassword.uuid;
                localStorage.setItem("user_credentials", JSON.stringify(newUserCredentials));
                initCurrentUser();
            }).catch((reason) => {
                console.error("Unable to retrieve application password: " + JSON.stringify(newUserCredentials));
            })
        }
        if(searchParams.get("do") === "disconnect"){
            localStorage.removeItem("user_credentials");
            setCurrentUser(null);
        }
    }

    useEffect(() => {
        initCurrentUser();
        handleAuthentication();
        initLikes();
    }, []);

    return (
      <div className={"App"}>
          <UserContext.Provider value={userContextValue}>
              <LikesContext.Provider value={likesContextValue}>
                  <RouterProvider router={router}/>
              </LikesContext.Provider>
          </UserContext.Provider>
      </div>
    );
}

export default App;
