import React, {useRef} from "react";

interface BubbleTextAreaProps {
    className? : string,
    value : string,
    onChange : (newText:string) => void,
    maxLines : number,
}

const BubbleTextArea : React.FC<BubbleTextAreaProps> = ({className, value, onChange , maxLines}) => {
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    const lineHeight : number = 22;
    let lastOKValue : string = value;

    const handleChange = () => {
        if(!textareaRef.current) return;
        if( countLines() > maxLines ){
            textareaRef.current.value = lastOKValue;
        }else{
            lastOKValue = textareaRef.current.value;
        }
        onChange(textareaRef.current.value);
    }

    const countLines = () => {
        if(!textareaRef.current) return 0;
        const scrollHeight : number = textareaRef.current.scrollHeight;
        return Math.floor(scrollHeight / lineHeight);
    }

    return (
        <textarea ref={textareaRef} className={className} rows={maxLines}
                  placeholder="Tapez votre texte ici."
                  value={value}
                  onFocus={()=>handleChange()}
                  onBlur={()=>handleChange()}
                  onKeyUp={()=>handleChange()}
                  onChange={()=>handleChange()}></textarea>
    )
}

export default BubbleTextArea;